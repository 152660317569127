import { Button } from "antd";

/**
 * Dial pad button properties.
 */
type DialPadButtonProps = {
  label: string;
  onClick: (args: { value: string | number }) => void;
  value: string | number;
};

const DialPadButton = ({ label, onClick, value }: DialPadButtonProps) => {
  return (
    <Button
      icon={
        <div
          className={`p-2.5 w- rounded-full justify-center items-center gap-2.5 flex`}
        >
          <div className="flex-col justify-start items-center inline-flex">
            <div className="text-slate-600 text-[24px] font-normal font-['Mont-SemiBold'] leading-[44.20px]">
              {value}
            </div>
            <div className="text-gray-400 text-sm font-normal font-['Mont-SemiBold'] leading-[17.50px]">
              {label}
            </div>
          </div>
        </div>
      }
      onClick={() => onClick({ value })}
      shape="circle"
      style={{
        height: 80,
        width: 80,
      }}
      type="default"
    />
  );

  return (
    <div
      className={`w-[80px] h-[80px] p-2.5 w- rounded-full border-2 border-solid border-slate-200 justify-center items-center gap-2.5 flex cursor-pointer hover:bg-slate-100`}
      onClick={() => onClick({ value })}
    >
      <div className="flex-col justify-start items-center inline-flex">
        <div className="text-slate-600 text-[24px] font-normal font-['Mont-SemiBold'] leading-[44.20px]">
          {value}
        </div>
        <div className="text-gray-400 text-sm font-normal font-['Mont-SemiBold'] leading-[17.50px]">
          {label}
        </div>
      </div>
    </div>
  );
};

export type DialPadProps = {
  onClick: DialPadButtonProps["onClick"];
  size?: number | string;
};

const dialPadButtons = [
  { label: "-", value: 1 },
  { label: "ABC", value: 2 },
  { label: "DEF", value: 3 },
  { label: "GHI", value: 4 },
  { label: "JKL", value: 5 },
  { label: "MNO", value: 6 },
  { label: "PQRS", value: 7 },
  { label: "TUV", value: 8 },
  { label: "WXYZ", value: 9 },
  { label: "", value: "*" },
  { label: "", value: 0 },
  { label: "", value: "#" },
];

export const DialPad = ({ onClick, size = "[80px]" }: DialPadProps) => {
  return (
    <div className="bg-white p-8">
      <div className="w-full flex-col justify-center items-center gap-4 inline-flex">
        <div className="justify-start items-start gap-4 inline-flex">
          <DialPadButton label="-" value={1} onClick={onClick} />
          <DialPadButton label="ABC" value={2} onClick={onClick} />
          <DialPadButton label="DEF" value={3} onClick={onClick} />
        </div>
        <div className="justify-start items-start gap-4 inline-flex">
          <DialPadButton label="GHI" value={4} onClick={onClick} />
          <DialPadButton label="JKL" value={5} onClick={onClick} />
          <DialPadButton label="MNO" value={6} onClick={onClick} />
        </div>
        <div className="justify-start items-start gap-4 inline-flex">
          <DialPadButton label="PQRS" value={7} onClick={onClick} />
          <DialPadButton label="TUV" value={8} onClick={onClick} />
          <DialPadButton label="WXYZ" value={9} onClick={onClick} />
        </div>
        <div className="justify-start items-start gap-4 inline-flex">
          <DialPadButton label="" value="*" onClick={onClick} />
          <DialPadButton label="" value={0} onClick={onClick} />
          <DialPadButton label="" value="#" onClick={onClick} />
        </div>
      </div>
    </div>
  );
};
