/**
 * TODO:
 * The status of the communication.
 */
export enum CommunicationInboundStatus {
  COMPLETED = "Completed",
  REJECTED = "Rejected",
  RINGING = "Ringing",
  MISSED = "Missed",
  ONGOING = "Ongoing",
}

export enum CommunicationOutboundStatus {
  COMPLETED = "Completed",
  REJECTED = "Rejected",
  MISSED = "Missed",
  ONGOING = "Ongoing",
}
