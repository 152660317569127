/**
 * Third-party libraries.
 */
import { Call } from "@twilio/voice-sdk";
import { Alert } from "antd";
import { PropsWithChildren } from "react";

/**
 * Project components.
 */
import {
  CommunicationLogCard,
  CommunicationLogCardProps,
} from "@/components/client/communication";
import { useApplicationContext } from "../context";
import { UserAvailabilityStatus } from "../graphql";

// =============================================================================
// Communication Log Row
// =============================================================================

/**
 * A row in the communication log list.
 * This provides a container with a bottom border to separate the rows.
 */
const CommunicationLogRow = ({
  children,
  className,
}: PropsWithChildren<{
  className?: string;
}>) => {
  return (
    <div
      className={`self-stretch bg-white border-b border-slate-200 flex-col justify-start items-start gap-4 flex ${className}`}
      style={{
        borderBottom: "1px solid rgba(0,0,0,0.1)",
      }}
    >
      {children}
    </div>
  );
};

// =============================================================================
// Communication Log Group
// =============================================================================

type CommunicationLogDataGroup = {
  /**
   * Logical group label for the communication logs.
   *
   * @example "Today"
   * @example "Yesterday"
   * @example "01 Jan 2024"
   */
  label: string;
  logs: CommunicationLogCardProps[];
};

type CommunicationLogGroupProps = {
  data: CommunicationLogDataGroup;
};

/**
 * Groups the communication logs together.
 * We usually use the
 */
export const CommunicationLogGroup = ({ data }: CommunicationLogGroupProps) => {
  return (
    <div className="">
      <CommunicationLogRow
        key={data.label}
        className="bg-white sticky top-0 z-10"
      >
        <div className="px-4 py-2 flex items-center">
          <div className="text-slate-600 text-sm font-normal">{data.label}</div>
        </div>
      </CommunicationLogRow>
      {data.logs.map((log) => (
        <CommunicationLogRow key={log.time}>
          <CommunicationLogCard {...log} />
        </CommunicationLogRow>
      ))}
    </div>
  );
};

// =============================================================================
// Communication Log
// =============================================================================

export type CommunicationLogProps = {
  /**
   * Active Twilio Call instance.
   */
  call?: Call | null;
  /**
   * Past communication logs.
   *
   * Pass an empty array if there are no logs.
   */
  data: CommunicationLogGroupProps["data"][];
  /**
   *
   * @param value
   * @returns
   */
  onSearch: (args: {
    /**
     * Search keyword.
     */
    keyword: string;
  }) => void;
};

export const CommunicationLog = ({ data }: CommunicationLogProps) => {
  const { userAvailabilityStatus } = useApplicationContext();

  return (
    <div className="w-full h-full bg-slate-50 border-r border-slate-200 flex-col justify-start items-start inline-flex min-w-[340px]">
      {userAvailabilityStatus === UserAvailabilityStatus.Offline && (
        <Alert
          className="w-full"
          message="Offline: You won't receive calls."
          type="warning"
          showIcon
          closable
        />
      )}
      {/* <CommunicationLogRow>
        <div className="w-[340px] h-16 p-4 bg-white border-b border-slate-200 flex-col justify-center items-start gap-2 inline-flex">
          <div className="self-stretch justify-start items-center gap-2 inline-flex">
            <div className="grow shrink basis-0 flex-col justify-start items-start gap-1 inline-flex">
              <Input.Search
                allowClear
                className="bg-violet-50 rounded border border-slate-200"
                // enterButton
                // loading
                placeholder="Search"
                onSearch={() => {}}
                variant="filled"
              />
            </div>
            <Icon src="filter" />
          </div>
        </div>
      </CommunicationLogRow> */}
      <div className="w-full h-full overflow-y-auto overflow-x-hidden">
        {data.map((group) => (
          <CommunicationLogGroup key={group.label} data={group} />
        ))}
      </div>
    </div>
  );
};
