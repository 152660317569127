/**
 * Project components.
 */
import { CommunicationDirection } from "@/components/client/communication";
import { Call } from "@twilio/voice-sdk";

/**
 * Get the Communication direction based on the Twilio Call instance.
 *
 * @returns The communication direction.
 */
export const getCommunicationDirection = (
  /**
   * Twilio call instance.
   */
  call: Call
) => {
  if (!call) {
    throw new Error("Call is not defined.");
  }

  switch (call.direction) {
    case Call.CallDirection.Incoming:
      return CommunicationDirection.INBOUND;
    case Call.CallDirection.Outgoing:
      return CommunicationDirection.OUTBOUND;
    default:
      throw new Error("Invalid call direction.");
  }
};

/**
 * Get the customer number based on the Twilio Call instance.
 *
 * @returns The customer phone number.
 */
export const getCustomerPhoneNumber = (
  /**
   * Twilio call instance.
   */
  call: Call
) => {
  if (!call) {
    throw new Error("Call is not defined.");
  }

  switch (call.direction) {
    case Call.CallDirection.Incoming:
      return call?.parameters["From"];
    case Call.CallDirection.Outgoing:
      /**
       * Outgoing call is passing the customer number in the custom parameters.
       * @see https://www.twilio.com/docs/voice/sdks/javascript/twiliocall#callcustomparameters
       */
      return call?.customParameters.get("To")?.toString();
    default:
      throw new Error("Invalid call direction.");
  }
};
