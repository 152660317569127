/**
 * Third-party libraries.
 */
import { Call } from "@twilio/voice-sdk";
import { Button } from "antd";
import { useMemo, useState } from "react";

/**
 * Project components.
 */
import {
  CommunicationDirection,
  CommunicationInboundStatus,
  CommunicationOutboundStatus,
  CommunicationStatus,
} from "@/components/client/communication";
import { Icon } from "@/components/client/icon";
import { StringUtility } from "@/components/common/string";

// import "./communication-log-card.css";

/**
 * Communication log card properties.
 */
export type CommunicationLogCardProps = {
  /**
   * Unique identifier for the communication entry.
   */
  id: string;
  /**
   * Name of the agent associated with this entry.
   */
  agentName: string;
  /**
   * Twilio Call object related to the communication log entry.
   *
   * This can be used for interacting with the call.
   */
  call?: Call;
  /**
   * Name or Phone Number of the customer associated with this entry.
   */
  customerNameOrPhoneNumber: string;
  /**
   * Indicates whether the communication is to or from the customer.
   */
  direction: CommunicationDirection;
  /**
   * Status of the communication when it ended.
   */
  status: CommunicationStatus<CommunicationDirection>;
  /**
   * The the communication started.
   */
  time?: string;
};

export const CommunicationLogCard = ({
  agentName,
  call,
  customerNameOrPhoneNumber,
  direction,
  status,
  time,
}: CommunicationLogCardProps) => {
  /**
   * Indicates whether the call has been accepted or not.
   *
   * Outbound calls are automatically marked as accepted.
   */
  const [callAccepted, setCallAccepted] = useState<boolean>(
    direction === CommunicationDirection.INBOUND ? false : true
  );

  /**
   * The icon to display based on the status of the communication.
   */
  const icon = useMemo(() => {
    switch (status) {
      case CommunicationInboundStatus.COMPLETED:
      case CommunicationOutboundStatus.COMPLETED:
        return "phone-down";
      case CommunicationInboundStatus.RINGING:
      case CommunicationInboundStatus.ONGOING:
      case CommunicationOutboundStatus.ONGOING:
      case CommunicationInboundStatus.MISSED:
      case CommunicationOutboundStatus.MISSED:
      case CommunicationInboundStatus.REJECTED:
      case CommunicationOutboundStatus.REJECTED:
        return "phone";
      default:
        throw new Error(`Unknown status: ${status}`);
    }
  }, [status]);

  /**
   * The colors to display based on the status of the communication.
   * This includes the following:
   * - Background color of the card
   * - Icon color
   * - Text color
   */
  const color = useMemo(() => {
    switch (status) {
      case CommunicationInboundStatus.RINGING:
        // Make the card green if the call has been accepted.
        if (callAccepted) {
          return {
            background: "bg-semantic-green",
            icon: "text-white",
            text: "text-white",
          };
        }

        return {
          background: "bg-sky-100",
          icon: "text-black",
          text: "",
        };
      case CommunicationInboundStatus.ONGOING:
      case CommunicationOutboundStatus.ONGOING:
        return {
          background: "bg-semantic-green",
          icon: "text-white",
          text: "text-white",
        };
      case CommunicationInboundStatus.COMPLETED:
      case CommunicationOutboundStatus.COMPLETED:
        return {
          background: "bg-white",
          icon: "text-semantic-green",
          text: "",
        };
      case CommunicationInboundStatus.MISSED:
      case CommunicationOutboundStatus.MISSED:
      case CommunicationInboundStatus.REJECTED:
      case CommunicationOutboundStatus.REJECTED:
        return {
          background: "bg-white",
          icon: "text-semantic-red",
          text: "",
        };
      default:
        throw new Error(`Unknown status: ${status}`);
    }
  }, [callAccepted, status]);

  return (
    <div
      className={`self-stretch justify-start items-center gap-2 inline-flex p-4 ${color.background} animate-slide-left ${color.text}`}
    >
      <Icon src={icon} className={`${color.icon}`} />
      <div className="grow shrink basis-0 flex-col justify-center items-start gap-2 inline-flex">
        <div className="self-stretch h-9 flex-col justify-start items-start flex">
          <div className="self-stretch justify-between items-center inline-flex">
            <div
              className={`communication-log-card-status text-slate-600 ${color.text} text-sm font-normal font-['Mont-SemiBold'] leading-[17.50px]`}
            >
              {callAccepted ? "Ongoing" : status} ({direction})
            </div>
            <div
              className={`communication-log-card-time  text-gray-400  text-xs font-normal font-['Mont-SemiBold'] leading-none 
                ${!time ? "hidden" : ""} ${color.text} `}
            >
              {time}
            </div>
          </div>
          <div className="self-stretch justify-start items-center gap-2 inline-flex">
            <div
              className={`communication-log-card-contact-name ommunication-log-card-text grow shrink basis-0 text-gray-400 ${color.text} text-sm font-normal font-['Mont-Regular'] leading-[17.50px]`}
            >
              {customerNameOrPhoneNumber}
            </div>
          </div>
        </div>
        <div className="w-full justify-start items-center gap-1 inline-flex">
          <div className="w-4 h-4 bg-slate-100 rounded-[100px] justify-center items-center flex">
            <div className="w-4 h-4 relative">
              <div className="w-4 h-4 left-0 top-0 absolute bg-slate-100 rounded-full" />
              <div
                className={`communication-log-card-agent-initials left-[3px] top-[3px] absolute text-center text-slate-600 text-[8px] font-normal font-['Mont-SemiBold'] leading-[10.40px]`}
              >
                {StringUtility.getInitials({
                  input: agentName ?? "",
                  maxLength: 2,
                })}
              </div>
            </div>
          </div>
          <div
            className={`communication-log-card-agent-name text-slate-600 ${color.text} text-xs font-normal font-['Mont-SemiBold'] leading-none text-nowrap text-ellipsis overflow-hidden max-w-44`}
          >
            {agentName}
          </div>
        </div>
      </div>
      {status === CommunicationInboundStatus.RINGING && call && (
        <div className="justify-start items-start gap-2 flex">
          {!callAccepted && (
            <Button
              icon={<Icon src="phone" width={16} />}
              onClick={async () => {
                call?.accept();
                setCallAccepted(true);
              }}
              shape="circle"
              style={{
                backgroundColor: "var(--semantic-green)",
                height: 40,
                width: 40,
              }}
              type="primary"
            />
          )}
          <Button
            icon={<Icon src="phone-down" width={16} />}
            onClick={async () =>
              !callAccepted ? call?.reject() : call?.disconnect()
            }
            shape="circle"
            style={{
              backgroundColor: "var(--semantic-red)",
              height: 40,
              width: 40,
            }}
            type="primary"
          />
        </div>
      )}
    </div>
  );
};
