"use client";

/**
 * Third-party libraries.
 */
import { Button, Input, Select } from "antd";
import { useState } from "react";

/**
 * Project components.
 */
import { DialPad } from "@/components/client/dial-pad";
import { Icon } from "@/components/client/icon";

const Country = {
  Singapore: {
    code: "+65",
    flag: "🇸🇬",
  },
  UnitedStates: {
    code: "+1",
    flag: "🇺🇸",
  },
};

export type DialerProps = {
  /**
   * Callback when the user makes a call.
   */
  onCall: (args: { phoneNumber: string }) => void;
  /**
   * Indicates if the dialer is visible.
   */
  visible?: boolean;
};

/**
 * A dialer component which allows the user to make calls.
 * Contains an input field for the phone number, dial pad, contact list, and call controls.
 */
export function Dialer({ onCall, visible }: DialerProps) {
  const [countryCode, setCountryCode] = useState<string>(
    Country.UnitedStates.code
  );
  const [phoneNumber, setPhoneNumber] = useState<string>("7167957921");
  // const [phoneNumber, setPhoneNumber] = useState<string>("");

  if (!visible) {
    return null;
  }

  return (
    <div className="w-full h-full bg-white">
      <div className="w-full h-[66px] p-4 bg-white border-b border-slate-200 justify-between items-center inline-flex">
        <div className="text-slate-600 text-2xl font-normal font-['Mont-SemiBold'] leading-[31.20px]">
          Dialer
        </div>
        <div className="p-0.5 bg-slate-100 rounded-md justify-start items-start flex">
          <div className="w-10 px-3 py-2 bg-white rounded justify-center items-center gap-2.5 flex">
            <Button
              icon={<Icon src="dial-pad" height={24} width={24} />}
              size="small"
              type="text"
            ></Button>
          </div>
          <div className="w-10 px-3 py-2 rounded justify-center items-center gap-2.5 flex">
            <Button
              icon={<Icon src="contact" height={24} width={24} />}
              size="small"
              type="text"
            />
          </div>
        </div>
      </div>
      <div className="w-full flex py-6 bg-violet-50 border-b border-slate-200">
        <div className="flex items-center justify-between gap-3 px-4 w-full">
          <Input
            addonBefore={
              <Select
                className="bg-transparent"
                options={Object.entries(Country).map(([key, value]) => {
                  return {
                    label: value.flag,
                    value: value.code,
                  };
                })}
                onChange={(value) => {
                  setCountryCode(value.toString());
                }}
                value={countryCode}
              />
            }
            className="text-xl w-full"
            maxLength={10}
            onChange={(event) => {
              setPhoneNumber(event.target.value);
            }}
            style={{
              background: "none",
              fontWeight: "bold",
              fontSize: "34px",
            }}
            value={phoneNumber}
          />
          {/* <Flag />
          <Icon src="caret-down" />
          <Input
            className="grow shrink basis-0 text-slate-600 text-[34px] font-normal font-['Mont-SemiBold'] leading-[44.20px]"
            value="+65 9123 4567"
          /> */}
          <Icon src="copy" />
        </div>
      </div>
      <DialPad
        onClick={({ value }) => {
          setPhoneNumber((previousValue) => {
            return (previousValue ?? "").toString() + value.toString();
          });
        }}
      />
      <div className="flex justify-center items-center">
        <Button
          icon={<Icon src="phone" color="white" width={40} />}
          onClick={function (): void {
            onCall({ phoneNumber: `${countryCode}${phoneNumber}` });
          }}
          shape="circle"
          style={{
            backgroundColor: "var(--semantic-green)",
            height: 80,
            width: 80,
          }}
          type="primary"
        />
      </div>
    </div>
  );
}
