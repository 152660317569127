"use client";

/**
 * Third-party libraries.
 */
import { useCallback, useLayoutEffect, useState } from "react";

/**
 * Project components.
 */
import { useAuthenticationContext } from "@/components/client/authentication/authentication-context";
import { CallControlSection, CallUtility } from "@/components/client/call";
import {
  CommunicationDirection,
  CommunicationInboundStatus,
  CommunicationLog,
  CommunicationLogProps,
} from "@/components/client/communication";
import { useApplicationContext } from "@/components/client/context";
import { Dialer } from "@/components/client/dialer";
import { ApplicationDefaultProtectedPageLayout } from "@/components/client/layout";
import { useTwilioContext } from "@/components/client/twilio";
import { Button } from "antd";

/**
 * Home page.
 * This is requires authentication to be accessed.
 */
function HomePage() {
  // ===========================================================================
  // ===========================================================================
  // Hooks
  // ===========================================================================
  // ===========================================================================

  const { mockData, showDialer, setShowDialer } = useApplicationContext();
  const { user } = useAuthenticationContext();
  const { call, calls, selectedCommunicationLog } = useTwilioContext();

  // ===========================================================================
  // ===========================================================================
  // States
  // ===========================================================================
  // ===========================================================================

  const [communicationLogs, setCommunicationLogs] = useState<
    CommunicationLogProps["data"]
  >([]);

  // ===========================================================================
  // ===========================================================================
  // Functions
  // ===========================================================================
  // ===========================================================================

  const onDialerCall = useCallback(
    async ({
      phoneNumber,
    }: {
      /**
       * Phone number to call.
       */
      phoneNumber: string;
    }) => {
      // Initiate the voice call.
      await call({
        phoneNumber,
      });

      // Hide the dialer.
      setShowDialer(false);
    },
    [call, setShowDialer]
  );

  // ===========================================================================
  // ===========================================================================
  // Effects
  // ===========================================================================
  // ===========================================================================

  useLayoutEffect(() => {
    if (mockData) {
      setCommunicationLogs([
        {
          label: "Today",
          logs: [
            {
              id: "1",
              agentName: "John Doe",
              customerNameOrPhoneNumber: "Jane Doe",
              direction: CommunicationDirection.INBOUND,
              status: CommunicationInboundStatus.RINGING,
              time: "11:54 AM",
            },
            {
              id: "2",
              agentName: "Jake Doe",
              customerNameOrPhoneNumber: "Josh Doe",
              direction: CommunicationDirection.OUTBOUND,
              status: CommunicationInboundStatus.ONGOING,
              time: "11:50 AM",
            },
            {
              id: "3",
              agentName: "John Smith",
              customerNameOrPhoneNumber: "Jane Smith",
              direction: CommunicationDirection.OUTBOUND,
              status: CommunicationInboundStatus.MISSED,
              time: "3:30 PM",
            },
            {
              id: "4",
              agentName: "Alice Johnson",
              customerNameOrPhoneNumber: "Bob Johnson",
              direction: CommunicationDirection.INBOUND,
              status: CommunicationInboundStatus.COMPLETED,
              time: "9:45 AM",
            },
            {
              id: "5",
              agentName: "John Doe",
              customerNameOrPhoneNumber: "Jane Doe",
              direction: CommunicationDirection.INBOUND,
              status: CommunicationInboundStatus.MISSED,
              time: "10:30 AM",
            },
            {
              id: "6",
              agentName: "Jake Doe",
              customerNameOrPhoneNumber: "Josh Doe",
              direction: CommunicationDirection.OUTBOUND,
              status: CommunicationInboundStatus.COMPLETED,
              time: "9:45 AM",
            },
            {
              id: "7",
              agentName: "John Smith",
              customerNameOrPhoneNumber: "Jane Smith",
              direction: CommunicationDirection.OUTBOUND,
              status: CommunicationInboundStatus.MISSED,
              time: "3:30 PM",
            },
            {
              id: "8",
              agentName: "Alice Johnson",
              customerNameOrPhoneNumber: "Bob Johnson",
              direction: CommunicationDirection.INBOUND,
              status: CommunicationInboundStatus.COMPLETED,
              time: "9:45 AM",
            },
            {
              id: "9",
              agentName: "John Doe",
              customerNameOrPhoneNumber: "Jane Doe",
              direction: CommunicationDirection.INBOUND,
              status: CommunicationInboundStatus.MISSED,
              time: "10:30 AM",
            },
            {
              id: "10",
              agentName: "Jake Doe",
              customerNameOrPhoneNumber: "Josh Doe",
              direction: CommunicationDirection.OUTBOUND,
              status: CommunicationInboundStatus.COMPLETED,
              time: "9:45 AM",
            },
            {
              id: "11",
              agentName: "John Smith",
              customerNameOrPhoneNumber: "Jane Smith",
              direction: CommunicationDirection.OUTBOUND,
              status: CommunicationInboundStatus.MISSED,
              time: "3:30 PM",
            },
          ],
        },
        {
          label: "Yesterday",
          logs: [
            {
              id: "12",
              agentName: "John Smith",
              customerNameOrPhoneNumber: "Jane Smith",
              direction: CommunicationDirection.OUTBOUND,
              status: CommunicationInboundStatus.MISSED,
              time: "3:30 PM",
            },
            {
              id: "13",
              agentName: "Alice Johnson",
              customerNameOrPhoneNumber: "Bob Johnson",
              direction: CommunicationDirection.INBOUND,
              status: CommunicationInboundStatus.COMPLETED,
              time: "9:45 AM",
            },
            {
              id: "14",
              agentName: "John Doe",
              customerNameOrPhoneNumber: "Jane Doe",
              direction: CommunicationDirection.INBOUND,
              status: CommunicationInboundStatus.MISSED,
              time: "10:30 AM",
            },
            {
              id: "15",
              agentName: "Jake Doe",
              customerNameOrPhoneNumber: "Josh Doe",
              direction: CommunicationDirection.OUTBOUND,
              status: CommunicationInboundStatus.COMPLETED,
              time: "9:45 AM",
            },
            {
              id: "16",
              agentName: "John Smith",
              customerNameOrPhoneNumber: "Jane Smith",
              direction: CommunicationDirection.OUTBOUND,
              status: CommunicationInboundStatus.MISSED,
              time: "3:30 PM",
            },
            {
              id: "17",
              agentName: "Alice Johnson",
              customerNameOrPhoneNumber: "Bob Johnson",
              direction: CommunicationDirection.INBOUND,
              status: CommunicationInboundStatus.COMPLETED,
              time: "9:45 AM",
            },
          ],
        },
        {
          label: "Two Days Ago",
          logs: [
            {
              id: "18",
              agentName: "John Doe",
              customerNameOrPhoneNumber: "Jane Doe",
              direction: CommunicationDirection.INBOUND,
              status: CommunicationInboundStatus.MISSED,
              time: "10:30 AM",
            },
            {
              id: "19",
              agentName: "Jake Doe",
              customerNameOrPhoneNumber: "Josh Doe",
              direction: CommunicationDirection.OUTBOUND,
              status: CommunicationInboundStatus.COMPLETED,
              time: "9:45 AM",
            },
            {
              id: "20",
              agentName: "John Smith",
              customerNameOrPhoneNumber: "Jane Smith",
              direction: CommunicationDirection.OUTBOUND,
              status: CommunicationInboundStatus.MISSED,
              time: "3:30 PM",
            },
            {
              id: "21",
              agentName: "Alice Johnson",
              customerNameOrPhoneNumber: "Bob Johnson",
              direction: CommunicationDirection.INBOUND,
              status: CommunicationInboundStatus.COMPLETED,
              time: "9:45 AM",
            },
            {
              id: "22",
              agentName: "John Doe",
              customerNameOrPhoneNumber: "Jane Doe",
              direction: CommunicationDirection.INBOUND,
              status: CommunicationInboundStatus.MISSED,
              time: "10:30 AM",
            },
            {
              id: "23",
              agentName: "Jake Doe",
              customerNameOrPhoneNumber: "Josh Doe",
              direction: CommunicationDirection.OUTBOUND,
              status: CommunicationInboundStatus.COMPLETED,
              time: "9:45 AM",
            },
          ],
        },
      ]);

      return;
    }

    if (!calls.length) {
      setCommunicationLogs([
        {
          label: "Today",
          logs: [],
        },
      ]);

      return;
    }

    calls.forEach((call) => {
      console.log({ status: call?.status(), call });

      /**
       * The direction of the call.
       */
      const callDirection = CallUtility.getCommunicationDirection(call);

      /**
       * The phone number of the customer.
       */
      const customerPhoneNumber = CallUtility.getCustomerPhoneNumber(call);

      setCommunicationLogs([
        {
          label: "Today",
          logs: [
            {
              agentName: user?.name ?? "",
              call,
              customerNameOrPhoneNumber: customerPhoneNumber ?? "",
              direction: callDirection,
              id: call.parameters.CallSid,
              status: CommunicationInboundStatus.RINGING,
            },
          ],
        },
      ]);
    });
  }, [calls, mockData, user?.name]);

  return (
    <ApplicationDefaultProtectedPageLayout>
      <div className="flex h-full">
        <div className="h-full w-full grid grid-cols-12 grid-rows-1">
          <div className="col-span-4 2xl:col-span-3">
            <CommunicationLog data={communicationLogs} onSearch={() => {}} />
          </div>
          <div className="col-span-4 2xl:col-span-3">
            <div className="w-full h-full border-r border-slate-200 flex-col justify-start items-start inline-flex min-w-[380]">
              {selectedCommunicationLog?.call && !showDialer && (
                <CallControlSection call={selectedCommunicationLog.call} />
              )}
              <Dialer onCall={onDialerCall} visible={showDialer} />
            </div>
          </div>
          <div className="col-span-4">
            <Button
              onClick={async () => {
                await call({
                  phoneNumber: "queue",
                });
              }}
              type="primary"
              className="hidden"
            >
              Dequeue
            </Button>
          </div>
        </div>
        {/* {call && (
          <div className="w-480 h-full p-12 bg-white flex-col justify-center items-center gap-4 inline-flex">
            <div className="self-stretch h-40 flex-col justify-center items-center gap-2 flex">
              <div className="text-slate-600 text-sm font-normal font-Mont-SemiBold leading-17.5">
                Incoming Call
              </div>
              <div className="w-16 h-16 relative bg-slate-100 rounded-full flex items-center justify-center">
                <Icon src="person" />
              </div>
              <div className="self-stretch h-9 flex-col justify-start items-start flex">
                <div className="self-stretch text-center text-slate-600 text-sm font-normal font-Mont-SemiBold leading-17.5">
                  [8210] Elle Cheong/ Timothy Cheong (P4 2024), Daisy Cheong
                  (S1, 2024)
                </div>
              </div>
              <div className="self-stretch text-center text-gray-400 text-sm font-normal font-Mont-Regular leading-17.5">
                +65 8383 5061
              </div>
            </div>
            <div className="justify-start items-start gap-2 inline-flex">
              <div
                className="p-3 bg-semantic-green rounded-full justify-center items-center gap-2.5 flex"
                onClick={() => call?.accept()}
              >
                <Icon src="phone" color="white" />
              </div>
              <div
                className="p-3 bg-semantic-red rounded-full justify-center items-center gap-2.5 flex"
                onClick={() => call?.disconnect()}
              >
                <Icon src="phone" color="white" />
              </div>
            </div>
          </div>
        )} */}
      </div>
    </ApplicationDefaultProtectedPageLayout>
  );
}

export default HomePage;
