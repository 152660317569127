/**
 * Third-party libraries.
 */
import { Button } from "antd";
import { useEffect, useState } from "react";

/**
 * Project components.
 */
import { Icon } from "@/components/client/icon";
import { User } from "@/components/client/images";
import { TimeUtility } from "@/components/common/time";

/**
 * Call control card properties.
 */
export type CallControlCardProps = {
  /**
   * Callbacks for the call control card.
   */
  callback?: {
    onRefresh?: () => void;
    onMute: () => void;
    onPause?: () => void;
    onEnd: () => void;
  };
  /**
   * Date and time the call was initiated.
   */
  date: Date;
  /**
   * Controls the mute button state.
   */
  muted?: boolean;
  /**
   * Client phone number.
   */
  phoneNumber: string;
};

/**
 * Active call control card.
 * Includes a timer, client phone number, and call control buttons (mute, end call).
 */
export function CallControlCard({
  callback,
  date,
  phoneNumber,
  muted = false,
}: CallControlCardProps) {
  // ===========================================================================
  // ===========================================================================
  // States
  // ===========================================================================
  // ===========================================================================
  /**
   * Duration of the call in milliseconds
   */
  const [duration, setDuration] = useState(0);

  function formatDuration(duration: number): string {
    const hours = Math.floor(duration / 3600000);
    const minutes = Math.floor((duration % 3600000) / 60000);
    const seconds = Math.floor((duration % 60000) / 1000);
    return `${padZero(hours)}:${padZero(minutes)}:${padZero(seconds)}`;
  }

  function padZero(num: number): string {
    return num.toString().padStart(2, "0");
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setDuration(() => new Date().getTime() - date.getTime());
    }, 1000);

    return () => clearInterval(interval);
  }, [date]);

  return (
    <div className="self-stretch h-[276px] p-[30px] bg-white flex-col justify-center items-center gap-4 flex">
      <div className="self-stretch h-40 flex-col justify-center items-center gap-2 flex">
        <div className="text-slate-600 text-sm font-normal font-['Mont-SemiBold'] leading-[17.50px]">
          Live {TimeUtility.formatDuration(duration)}
        </div>
        <div className="w-16 h-16 relative bg-slate-100 rounded-[100px]">
          <div className="w-16 h-16 left-0 top-0 absolute bg-slate-100 rounded-full overflow-hidden">
            <User className="w-14 h-14 left-[4px] top-[8px] absolute" />
          </div>
        </div>
        {/* <div className="self-stretch h-9 flex-col justify-start items-start flex">
          <div className="self-stretch text-center text-slate-600 text-sm font-normal font-['Mont-SemiBold'] leading-[17.50px]">
            [8210] Elle Cheong/ Timothy Cheong (P4 2024), Daisy Cheong (S1,
            2024)
          </div>
        </div> */}
        <div className="self-stretch text-center text-gray-400 text-sm font-normal font-['Mont-Regular'] leading-[17.50px]">
          {phoneNumber}
        </div>
      </div>
      <div className="justify-start items-start gap-2 inline-flex h-10">
        <Button
          icon={
            <Icon src={muted ? "microphone-mute" : "microphone"} width={16} />
          }
          onClick={() => callback?.onMute()}
          shape="circle"
          style={{
            height: 40,
            width: 40,
          }}
          type="default"
        />
        <Button
          icon={<Icon src="phone-down" color="white" width={16} />}
          onClick={() => callback?.onEnd()}
          shape="circle"
          style={{
            backgroundColor: "var(--semantic-red)",
            height: 40,
            width: 40,
          }}
          type="primary"
        />
      </div>
    </div>
  );
}
