/**
 * Third-party libraries.
 */

/**
 * Project components.
 */
import { CallControlCard, CallUtility } from "@/components/client/call";
import {
  CallWithCustomProperties,
  useTwilioContext,
} from "@/components/client/twilio";

export type CallControlSectionProps = {
  /**
   * Active Twilio call.
   */
  call: CallWithCustomProperties;
};

export function CallControlSection({ call }: CallControlSectionProps) {
  const { selectedCommunicationLog, toggleMute } = useTwilioContext();

  return (
    <div className="w-full h-full border-r border-slate-200 flex-col justify-start items-start inline-flex min-w-[300px]">
      <CallControlCard
        callback={{
          onEnd: () => {
            call.disconnect();
          },
          onMute: () => {
            toggleMute();
          },
        }}
        date={call?.date!}
        muted={selectedCommunicationLog?.muted}
        phoneNumber={CallUtility.getCustomerPhoneNumber(call) ?? ""}
      />
      {/* <div className="self-stretch grow shrink basis-0 p-4 bg-slate-50 flex-col justify-start items-center gap-4 flex overflow-auto">
        <CallSummaryCard />
        <CallSummaryTagsCard />
        <CallNotesCard />
      </div>
      <div className="self-stretch p-4 bg-white border-t border-slate-200 justify-start items-center gap-2 inline-flex">
        <div className="grow shrink basis-0 flex-col justify-center items-start gap-2 inline-flex">
          <div className="self-stretch h-20 flex-col justify-start items-start flex">
            <div className="self-stretch grow shrink basis-0 pl-1.5 pr-1 pt-1.5 pb-1 bg-violet-50 rounded border border-slate-200 justify-end items-end inline-flex">
              <div className="grow shrink basis-0 self-stretch text-gray-400 text-sm font-normal font-['Mont-Regular'] leading-[17.50px]">
                Add notes for this task.
              </div>
              <img
                className="w-[8.71px] h-[8.71px]"
                src="https://via.placeholder.com/9x9"
              />
            </div>
          </div>
          <div className="justify-start items-center gap-2 inline-flex">
            <div className="w-6 h-6 relative" />
            <div className="w-6 h-6 relative" />
          </div>
        </div>
      </div> */}
    </div>
  );
}
