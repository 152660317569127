import Image from "next/image";

export function CallSummaryCard() {
  return (
    <div className="self-stretch h-[162px] bg-white rounded-md border border-slate-200 flex-col justify-start items-start flex">
      <div className="self-stretch p-4 border-b border-slate-200 justify-between items-center inline-flex">
        <div className="text-slate-600 text-sm font-normal font-['Mont-SemiBold'] leading-[17.50px]">
          Call Summary
        </div>
      </div>
      <div className="self-stretch h-28 p-4 flex-col justify-start items-start gap-4 flex">
        <div className="self-stretch h-20 flex-col justify-start items-start flex">
          <div className="self-stretch grow shrink basis-0 pl-1.5 pr-1 pt-1.5 pb-1 bg-violet-50 rounded border border-slate-200 justify-end items-end inline-flex">
            <div className="grow shrink basis-0 self-stretch text-gray-400 text-sm font-normal font-['Mont-Regular'] leading-[17.50px]">
              Add call summary
            </div>
            <Image
              className="w-[8.71px] h-[8.71px]"
              src="https://via.placeholder.com/9x9"
              alt="Call summary placeholder"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
